import axios, { AxiosError } from 'axios'
import { Either, left, right } from 'fp-ts/Either'

export interface FormData {
  name: string
  lastname: string
  email: string
  phone?: string
  check_in: string
  check_out: string
  adults: string
  children?: string
  ages?: string
  type: string
  message?: string
  privacy_policy: boolean
}

export interface ContactsFormSenderBackendConfiguration {
  formURL: string
}

export type ContactsFormSenderBackend = (
  data: FormData,
) => Promise<Either<AxiosError, void>>

export const ContactsFormSenderBackend =
  ({
    formURL,
  }: ContactsFormSenderBackendConfiguration): ContactsFormSenderBackend =>
  async (data): Promise<Either<AxiosError, void>> => {
    try {
      await axios({
        method: 'POST',
        url: formURL,
        headers: {
          'Content-type': 'application/json',
        },
        data,
      })

      const response = await axios({
        method: 'POST',
        url: `https://bedirect.bookingexpert.it/beacr/ws/customerRequestApi?hotel=44657&layout=15848&widgetapikey=44657_3bb9386784314c75986f25d0a49d5f18&firstname=${
          data.name
        }&lastname=${data.lastname}&email=${data.email}&phone=${
          data.phone
        }&request=${`Provenienza: Form Sito, Adulti: ${data.adults}, Bambini: ${data.children} (${data.ages}) - ${data.message}`}&checkin=${data.check_in.replace(
          /\//g,
          '-',
        )}&checkout=${data.check_out.replace(/\//g, '-')}&channel=34833`,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      })

      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }
